import { lazy, Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    createRoutesFromChildren,
    matchRoutes,
    useNavigationType,
    useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import AppProvider from "app/hoc/app_providers/app_providers";
import Root from "app/hoc/root/root";
import Layout from "app/hoc/layout/dashboard_layout";
import VendorLayout from "app/hoc/layout/business_layout";
import "./i18n";
import "app/styles/main.scss";

import PublicRoute from "app/hoc/public_route/public_route";
import ProtectedRoute from "app/hoc/protected_route/protected_route";

import Logout from "app/views/misc/logout/logout";
import URL from "app/constants/route_urls";

// Public views
import Login from "app/views/public/auth/login";
import Register from "app/views/public/auth/register";
import PasswordReset from "app/views/public/auth/password_reset";
import ResetPasswordConfirm from "app/views/public/auth/reset_password_confirm";
import VerifyEmail from "app/views/public/auth/verify_email";

// Protected views
import Dashboard from "app/views/protected/dashboard/dashboard";
import Account from "app/views/protected/user/account/account";
import Profile from "app/views/protected/user/profile/profile";
import LocationConfirm from "app/views/public/auth/location_confirm";
import UpcomingRaces from "app/views/protected/races/upcoming/upcoming_races";
import PastRaces from "app/views/protected/races/past/past_races";
import RaceAdd from "app/views/protected/races/race_add/race_add";
import RaceImport from "app/views/protected/races/race_import/race_import";
import RaceDetails from "app/views/protected/races/race_details/race_details";
import CalendarMarketer from "app/views/protected/promote/calendar_marketer/calendar_marketer";
import VendorOffers from "app/views/protected/plan/offers/offers";
import Documents from "app/views/protected/plan/documents/documents";
import Sponsors from "app/views/protected/plan/sponsors/sponsors";
import VendorListings from "app/views/protected/business_dashboard/listings/listings";
import VendorBadges from "app/views/protected/business_dashboard/badges/badges";
import VendorQuoteRequests from "app/views/protected/business_dashboard/quote_requests/quote_requests";
import BusinessListing from "app/views/protected/business_dashboard/listings/listing/listing";

import Quotes from "app/views/protected/quote/quote";

// Misc. views
import ErrorPage from "app/views/misc/error_page/error";
import ForbiddenPage from "app/views/misc/forbidden_page/forbidden";
import BudgetBuilder from "app/views/protected/budget/budget";
import AddReview from "app/views/protected/add_review/add_review";
import RaceReviews from "app/views/protected/races/race_reviews/race_reviews";
import ListingUpgradeRedirect from "app/views/protected/business_dashboard/listing_upgrade_redirect/listing_upgrade_redirect";
import ListingClaim from "app/views/protected/business_dashboard/listings/claim/claim";
import AutoLayout from "app/hoc/layout/auto_layout";

// Lazy views
const MembersJoin = lazy(() => import("app/views/protected/payments/members_join/members_join"));
const ListingCredits = lazy(
    () => import("app/views/protected/payments/listing_credits/listing_credits")
);
const ListingUpgrade = lazy(
    () => import("app/views/protected/payments/listing_upgrade/listing_upgrade")
);

if (import.meta.env.VITE_SENTRY_RELEASE && import.meta.env.PROD) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_KEY,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0,
        autoSessionTracking: false,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
    });
}

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: (
                <AppProvider>
                    <Root />
                </AppProvider>
            ),
            children: [
                {
                    path: URL.LOGIN,
                    element: (
                        <PublicRoute title="Sign In">
                            <Login />
                        </PublicRoute>
                    ),
                },
                {
                    path: URL.REGISTER,
                    element: (
                        <PublicRoute title="Sign Up">
                            <Register />
                        </PublicRoute>
                    ),
                },
                {
                    path: URL.PASSWORD_RESET,
                    element: (
                        <PublicRoute title="Reset Password">
                            <PasswordReset />
                        </PublicRoute>
                    ),
                },
                {
                    path: URL.VERIFY_PASSWORD_RESET,
                    element: (
                        <PublicRoute title="Reset Password">
                            <ResetPasswordConfirm />
                        </PublicRoute>
                    ),
                },
                {
                    path: URL.VERIFY_EMAIL,
                    element: (
                        <PublicRoute title="Verify Email">
                            <VerifyEmail />
                        </PublicRoute>
                    ),
                },
                {
                    path: URL.LOCATION_CONFIRM,
                    element: (
                        <PublicRoute title="Confirm Location">
                            <LocationConfirm />
                        </PublicRoute>
                    ),
                },
                {
                    path: URL.LOGOUT,
                    element: <Logout />,
                },
                {
                    path: `${URL.DASHBOARD}:route?`,
                    element: (
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: URL.RACES,
                    element: <Layout />,
                    children: [
                        {
                            path: URL.UPCOMING_RACES,
                            element: (
                                <ProtectedRoute title="Upcoming Races">
                                    <UpcomingRaces />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: URL.PAST_RACES,
                            element: (
                                <ProtectedRoute title="Past Races">
                                    <PastRaces />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: `${URL.RACES}:id/edit/`,
                            element: (
                                <ProtectedRoute>
                                    <RaceDetails />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: URL.RACE_IMPORT,
                            element: (
                                <ProtectedRoute title="Add Race">
                                    <RaceImport />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: `${URL.RACES}:pk/reviews/:review_uuid`,
                            element: (
                                <ProtectedRoute title="Race Review">
                                    <RaceReviews />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: `${URL.RACE_ADD}:id?`,
                            element: (
                                <ProtectedRoute title="Add Race">
                                    <RaceAdd />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: `${URL.RACES}:id/copy/`,
                            element: (
                                <ProtectedRoute title="Add Race">
                                    <RaceAdd />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.ACCOUNT,
                    element: <AutoLayout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Account">
                                    <Account />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.PROFILE,
                    element: <AutoLayout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Profile">
                                    <Profile />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.REPORTS,
                    element: <Layout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Race Calendar Wizard">
                                    <CalendarMarketer />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.SPONSORS,
                    element: <Layout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Sponsor Finder">
                                    <Sponsors />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: `${URL.OFFERS}:pk?`,
                    element: <Layout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Vendor Offers">
                                    <VendorOffers />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.DOCUMENTS,
                    element: <Layout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Documents & Templates">
                                    <Documents />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: "category/:category/",
                            element: (
                                <ProtectedRoute title="Doucments & Templates">
                                    <Documents />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: ":pk/",
                            element: (
                                <ProtectedRoute title="Doucments & Templates">
                                    <Documents />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.BUDGET_BUILDER,
                    element: <Layout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Budget Builder">
                                    <BudgetBuilder />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: `${URL.QUOTES}:pk/`,
                    element: <Layout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Quotes">
                                    <Quotes />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.ADD_REVIEW,
                    element: <Layout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute>
                                    <AddReview />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.FORBIDDEN,
                    element: <Layout />,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Forbidden">
                                    <ForbiddenPage />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.MEMBERS_JOIN,
                    element: (
                        <ProtectedRoute title="Upgrade">
                            <Suspense fallback={null}>
                                <MembersJoin />
                            </Suspense>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: URL.LISTING_CREDITS,
                    element: (
                        <ProtectedRoute title="Checkout">
                            <Suspense fallback={null}>
                                <ListingCredits />
                            </Suspense>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${URL.LISTING_UPGRADE}/`,
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute title="Upgrade Listing">
                                    <ListingUpgradeRedirect />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: ":pk/",
                            element: (
                                <ProtectedRoute>
                                    <Suspense fallback={null}>
                                        <ListingUpgrade />
                                    </Suspense>
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: URL.BUSINESS_DASHBOARD,
                    element: (
                        <ProtectedRoute>
                            <VendorLayout />
                        </ProtectedRoute>
                    ),
                    children: [
                        {
                            path: URL.BUSINESS_LISTINGS,
                            element: (
                                <ProtectedRoute title="Listings">
                                    <VendorListings />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: URL.BUSINESS_LISTINGS_ADD,
                            element: (
                                <ProtectedRoute title="Add Listing">
                                    <BusinessListing />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: `${URL.BUSINESS_LISTINGS}:id/claim/`,
                            element: (
                                <ProtectedRoute>
                                    <ListingClaim />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: `${URL.BUSINESS_LISTINGS}:id/edit/`,
                            element: (
                                <ProtectedRoute>
                                    <BusinessListing />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: `${URL.BUSINESS_QUOTES}:id?`,
                            element: (
                                <ProtectedRoute title="Quote Requests">
                                    <VendorQuoteRequests />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: URL.BUSINESS_BADGES,
                            element: (
                                <ProtectedRoute title="Badges">
                                    <VendorBadges />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: "*",
                            children: [
                                {
                                    path: "*",
                                    element: <ErrorPage errorCode="404" />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "*",
                    element: <Layout />,
                    children: [
                        {
                            path: "*",
                            element: <ErrorPage errorCode="404" />,
                        },
                    ],
                },
            ],
        },
    ],
    {
        future: {
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_relativeSplatPath: true,
        },
    }
);

const container = document.getElementById("app");
const root = createRoot(container as Element | DocumentFragment);

root.render(<RouterProvider router={router} />);
