import { ReactElement, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { message } from "antd";
import { _isEmpty } from "app/utils/helpers";
import { IUser } from "app/store/types/user.types";
import { DASHBOARD_SIDE_MENU } from "app/lib/navigation/navigation";
import { useGetWidgetRaces } from "app/utils/api/queries/races.query";
import TopNavigation from "app/components/modules/navigation/top_navigation/top_navigation";
import SideNavigation from "app/components/modules/navigation/side_navigation/side_navigation";
import LoadingScreen from "app/components/elements/loading_screen/loading_screen";
import GlobalModals from "app/components/modules/global_modals/global_modals";
import RaceSelect from "app/components/modules/race_select/race_select";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import "app/hoc/layout/layout.scss";
import MISC_CONSTANTS from "app/constants/misc";

message.config({
    top: 75,
    duration: 3,
    maxCount: 3,
});

const Layout = (): ReactElement | null => {
    const [messageApi, contextHolder] = message.useMessage();
    const location = useLocation();
    const { isAuthenticated, isLoading, user, getUser } = userSlice((state) => state);
    const { toggleNavbar, navbar } = modalsSlice((state) => state);
    const { isError: raceWidgetError } = useGetWidgetRaces({ display: "menu" });
    const [editRaceName, setEditRaceName] = useState<string | null>(null);
    const [addNewRace, setAddNewRace] = useState<string | null>(null);
    const currentDashboard = Storage.get(`${STORAGE_CONSTANTS.currentDashboard}`);

    // Add current dashboard to local storage
    useEffect(() => {
        if (user.pk && currentDashboard !== MISC_CONSTANTS.RACES_DASHBOARD) {
            Storage.set(`${STORAGE_CONSTANTS.currentDashboard}`, MISC_CONSTANTS.RACES_DASHBOARD);
        }
    }, [currentDashboard, user.pk]);

    useEffect(() => {
        if (!_isEmpty(raceWidgetError)) {
            messageApi.error({
                content: "An error ocurred while fetching the races for the Race select tool",
            });
        }
    }, [raceWidgetError, messageApi]);

    useEffect(() => {
        if (!_isEmpty(editRaceName)) {
            messageApi.success({
                content: `${editRaceName} has been updated`,
                onClose: () => {
                    Storage.remove(STORAGE_CONSTANTS.editRaceSuccess);
                    setEditRaceName(null);
                },
            });
        }
    }, [editRaceName, messageApi]);

    useEffect(() => {
        if (!_isEmpty(addNewRace)) {
            messageApi.success({
                content: `${addNewRace} has been added to your races`,
                onClose: () => {
                    Storage.remove(STORAGE_CONSTANTS.addRaceSuccess);
                    setAddNewRace(null);
                },
            });
        }
    }, [addNewRace, messageApi]);

    // Detect location change and display appropriate messages
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        const editRaceName = Storage.get(STORAGE_CONSTANTS.editRaceSuccess);
        const addRaceName = Storage.get(STORAGE_CONSTANTS.addRaceSuccess);

        if (!_isEmpty(editRaceName)) setEditRaceName(editRaceName);
        if (!_isEmpty(addRaceName)) setAddNewRace(addRaceName);
    }, [location]);

    // Local storage
    const payment_success_message = Storage.get(STORAGE_CONSTANTS.paymentSuccessMessage);
    const payment_error_message = Storage.get(STORAGE_CONSTANTS.paymentErrorMessage);
    const user_login_message = Storage.get(STORAGE_CONSTANTS.loginSuccessMessage);

    useEffect(() => {
        if (!_isEmpty(user_login_message)) {
            messageApi.success({
                content: user_login_message,
                onClose: () => {
                    Storage.remove(STORAGE_CONSTANTS.loginSuccessMessage);
                },
            });
        }
    }, [user_login_message, messageApi]);

    // If there is a token re-authenticate the user
    useEffect(() => {
        if (isAuthenticated && _isEmpty(user)) getUser();
    }, [user, isAuthenticated, getUser]);

    // Disable body scroll when navbar is toggled
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (navbar.isToggled) {
            document.body.classList.add("no-scroll");
            return () => {
                document.body.classList.remove("no-scroll");
            };
        }
    }, [navbar.isToggled]);

    useEffect(() => {
        if (!_isEmpty(payment_success_message)) {
            messageApi.success({
                content: payment_success_message,
                onClose: () => {
                    Storage.remove(STORAGE_CONSTANTS.paymentSuccessMessage);
                },
            });
        }
    }, [payment_success_message, messageApi]);

    useEffect(() => {
        if (!_isEmpty(payment_error_message)) {
            message.error({
                content: payment_error_message,
                onClose: () => {
                    Storage.remove(STORAGE_CONSTANTS.paymentErrorMessage);
                },
            });
        }
    }, [payment_error_message]);

    useEffect(() => {
        if (!_isEmpty(payment_error_message)) {
            messageApi.error({
                content: payment_error_message,
                onClose: () => {
                    Storage.remove(STORAGE_CONSTANTS.paymentErrorMessage);
                },
            });
        }
    }, [payment_error_message, messageApi]);

    if (isLoading && _isEmpty(user)) return <LoadingScreen type="layout" />;
    if (!isAuthenticated) return <Outlet />;

    return (
        <>
            {contextHolder}
            <main className={`layout ${navbar.isToggled ? "isToggled" : ""}`}>
                <div
                    role="presentation"
                    className={`layout__overlay ${navbar.isToggled ? "isVisible" : ""}`}
                    onClick={toggleNavbar}
                />
                <GlobalModals />
                <TopNavigation
                    user={user as IUser}
                    isNavbarToggled={navbar.isToggled}
                    toggleNavbar={toggleNavbar}
                    selectionTool={<RaceSelect />}
                    isBusinessDashboard={false}
                />
                <SideNavigation
                    user={user as IUser}
                    isNavbarToggled={navbar.isToggled}
                    toggleNavbar={toggleNavbar}
                    menuLinks={DASHBOARD_SIDE_MENU}
                    isBusinessDashboard={false}
                />
                <div className={`main-content ${navbar.isToggled ? "isToggled" : ""}`}>
                    <Outlet />
                </div>
            </main>
        </>
    );
};

export default Layout;
