import { ReactElement, useRef, useState, useMemo, useEffect, ReactNode, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
    message,
    Button,
    Typography,
    Upload,
    UploadFile,
    UploadProps,
    Row,
    Col,
    Input,
    Select,
} from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { IUser } from "app/store/types/user.types";
import racesSlice from "app/store/races/races.slice";
import { RaceDetailsType } from "app/types/races/race.types";

import { raceValidationSchema } from "app/lib/validation_schemas/race_validation.schema";
import { _isEmpty, DateUtils, imageValidator } from "app/utils/helpers";
import { RaceFormDataTypes } from "app/store/races/races.types";
import { Prompt } from "app/hooks";
import { detectChangedRaceData } from "app/utils/helpers/detect_changed_race_data";
import { deepEqual } from "app/utils/helpers/deep_equal";
import URL from "app/constants/route_urls";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import { useCreateRace, useUpdateRace } from "app/utils/api/mutations/races.mutation";
import { useRacesWidget } from "app/hooks/useRacesWidget";
import { InternetOutlined, PlusOutlined } from "assets";
import { getBase64 } from "app/utils/helpers/getBase64";
import { groupBy } from "app/utils/helpers/group_by";
import { QUERY_KEYS } from "app/utils/api/queries/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldErrors, useFieldArray, useForm } from "react-hook-form";
import FormItemWrapper from "app/components/elements/form/form_item_wrapper";
import { parseISO } from "date-fns";
import MISC_CONSTANTS from "app/constants/misc";
import TextArea from "antd/es/input/TextArea";
import DatePicker from "app/components/elements/form/datepicker";
import { useSelectOptions } from "app/hooks/useSelectField";
import { ChevronDownOutlined } from "assets/icons/icons";
import RaceEvent from "./race_event";
import "app/components/templates/add_edit_race/add_edit_race.scss";

const ComponentWithFormContext = ({
    initialData,
    values,
    isDirty,
    scrollToErrors,
    isSubmitting,
    errors,
}: {
    initialData: RaceDetailsType;
    values: RaceDetailsType;
    isDirty: boolean;
    scrollToErrors: (arg: string[]) => void;
    isSubmitting: boolean;
    errors: FieldErrors<RaceDetailsType>;
}): ReactElement => {
    const detectMemoizedChanges = useMemo(() => {
        const detectChanges = deepEqual(initialData, values);
        return !detectChanges;
    }, [initialData, values]);

    useEffect(() => {
        if (isSubmitting && Object.keys(errors).length > 0) {
            setTimeout(() => {
                scrollToErrors(Object.keys(errors));
            }, 100);
        }
    }, [isSubmitting, errors, scrollToErrors]);

    return (
        <Prompt
            when={detectMemoizedChanges && !isSubmitting && isDirty}
            message="Are you sure you want to leave this page? Any unsaved changes will be lost."
            beforeUnload
        />
    );
};

const AddEditRaceError = ({ children }: { children: ReactNode }) => (
    <div className="add-edit-race">
        <Typography.Title level={1}>Add/Edit Race</Typography.Title>
        <div className="add-edit-race__error">{children}</div>
    </div>
);

// TODO -> Extract the 'image upload' logic to another component
const AddEditRace = ({
    mode,
    user,
    formData,
    raceData,
}: {
    mode: "edit" | "add" | "copy";
    user: IUser;
    formData: Partial<RaceFormDataTypes>;
    raceData: RaceDetailsType;
}): ReactElement => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const addEditRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const redirectAfterSubmit: string =
        location?.state?.then || Storage.get(STORAGE_CONSTANTS.addRaceRedirect) || URL.RACES;
    const redirectAfterEdit: string | null = Storage.get(STORAGE_CONSTANTS.editRaceRedirect);
    const [savingRaceIsLoading, setSavingRaceIsLoading] = useState<boolean>(false);
    const { mutateAsync: createRace, data } = useCreateRace({});
    const { mutateAsync: updateRace } = useUpdateRace({});
    const { clearImportedRace, importedRace } = racesSlice((state) => state);
    const formRef = useRef<HTMLFormElement>(null);
    const [withRedirectState, setWithRedirectState] = useState<boolean>(false);

    const raceTypeOptions = useSelectOptions(
        formData?.types ? groupBy(formData?.types, "discipline") : []
    );
    const raceRegionOptions = useSelectOptions(formData?.regions);
    const raceTimezoneOptions = useSelectOptions(formData?.timezones);

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const formContext = useForm<RaceDetailsType>({
        resolver: yupResolver<any>(raceValidationSchema(user?.location.language)),
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            ...raceData,
        },
    });

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        trigger,
        getValues,
        setError,
        control,
        getFieldState,
        formState: { errors, isDirty, isSubmitting },
    } = formContext;

    const {
        fields: events,
        remove: removeEvent,
        insert: insertEvent,
    } = useFieldArray({
        control,
        name: "events",
    });

    const values = getValues();

    // Populate logo url if it already exists
    useEffect(() => {
        if (values.logo?.url) {
            setFileList([{ url: values.logo.url, uid: "1", name: "" }]);
        }
    }, [values.logo]);

    const handleLogoChange: UploadProps["beforeUpload"] = async (file): Promise<boolean> => {
        const [isValid, errorMessage] = imageValidator(file);
        if (!isValid) {
            setError("logo", { type: "manual", message: errorMessage });
            return false;
        }

        const fileUrl = await getBase64(file);
        const prepareFile = {
            uid: file.uid,
            name: file.name,
            url: fileUrl,
        };
        setFileList([prepareFile]);
        setValue("logo", {
            base64: prepareFile.url,
        });
        trigger("logo");

        return false;
    };

    useEffect(() => {
        addEditRef?.current?.classList.add("dataHasChanged");
        setTimeout(() => {
            addEditRef?.current?.classList.remove("dataHasChanged");
        }, 750);
    }, [raceData?.name]);

    const eventAddHandler = (): void => {
        insertEvent(events.length, { name: "" });
    };

    const { handleSelectRaceString } = useRacesWidget();

    const submitRace = async (values: RaceDetailsType, withRedirect: boolean): Promise<void> => {
        const valuesData = values;

        const updatedValues = detectChangedRaceData({
            originalData: raceData,
            newData: valuesData,
        });

        setWithRedirectState(withRedirect);

        setSavingRaceIsLoading(true);

        if (user.location.language === "en-gb")
            Reflect.set(valuesData, "timezone", { pk: 7, code: "BST" });

        try {
            if (mode === "add" || mode === "copy") await createRace({ formData: valuesData });
            if (mode === "edit") await updateRace({ formData: updatedValues });

            if (mode === "edit" && !withRedirect)
                message.success(`${valuesData.name} was successfully updated`);

            if (mode === "edit" && withRedirect)
                Storage.set(STORAGE_CONSTANTS.editRaceSuccess, valuesData.name);

            if (mode === "add" || mode === "copy")
                Storage.set(STORAGE_CONSTANTS.addRaceSuccess, valuesData.name);

            if (data && mode === "add") {
                const { pk, name } = data.result;
                handleSelectRaceString(JSON.stringify({ pk, name, type: "upcoming" }));
            }

            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RACES, "upcoming", 1] });

            const redirectTo =
                mode === "add" || mode === "copy" ? redirectAfterSubmit : redirectAfterEdit;

            if (importedRace?.race) clearImportedRace();

            if (withRedirect) navigate(redirectTo as string);
        } catch (error) {
            if (error?.response?.status === 500) {
                message.error("Something went wrong while submitting your data.");
                setSavingRaceIsLoading(false);
                return;
            }

            if (error?.response) {
                if (
                    Reflect.has(error.response.data, "non_field_errors") &&
                    typeof error.response.data.non_field_errors === "object"
                ) {
                    error.response.data.non_field_errors.forEach((error: any) =>
                        message.error(error)
                    );
                }

                Object.entries(error.response.data).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        setError(key as keyof RaceDetailsType, {
                            type: "manual",
                            message: value[0],
                        });
                        if (key === "events") {
                            value.forEach((ev: any, index) => {
                                if (Reflect.ownKeys(ev).length !== 0) {
                                    Reflect.ownKeys(ev).forEach((eventKey) => {
                                        const key =
                                            `events.${index}.${String(eventKey)}` as keyof RaceDetailsType;
                                        setError(key, {
                                            type: "manual",
                                            message: ev[eventKey][0],
                                        });
                                    });
                                }
                            });
                        }
                    }

                    setSavingRaceIsLoading(false);
                });
            }
        }

        setSavingRaceIsLoading(false);

        if (mode === "edit" && !_isEmpty(redirectAfterEdit))
            Storage.remove(STORAGE_CONSTANTS.editRaceRedirect);
    };

    const renderPageHeader = (): JSX.Element => {
        if (mode === "edit") return <Typography.Title level={1}>{raceData?.name}</Typography.Title>;
        return <Typography.Title level={1}>Add race</Typography.Title>;
    };

    const scrollToOnErrors = (errors: string[]): void => {
        const errorsOrder = [
            "name",
            "start_date",
            "type",
            "description",
            "website",
            "registration_page",
            "logo",
            "address",
            "city",
            "region",
            "postal_code",
            "timezone",
        ];
        try {
            const topNavigation = document.querySelector(".top-navbar__header") as HTMLElement;
            if (!topNavigation) throw new Error("Can't find top navigation element");

            const topNavigationHeight = topNavigation.offsetHeight;
            const errorKeys: string[] = errors;
            const firstError = errorsOrder.find((error) => errorKeys.includes(error));
            const element = document.querySelector(`[data-error=${firstError}]`) as HTMLDivElement;

            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: elementPosition - (topNavigationHeight + 30),
                    behavior: "smooth",
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    if (mode === "edit" && new Date(raceData?.start_date) < new Date()) {
        return (
            <AddEditRaceError>
                You cannot edit "{raceData?.name}" since it's a past race.
            </AddEditRaceError>
        );
    }

    const onFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(event.target.name as keyof RaceDetailsType, event.target.value, {
            shouldDirty: true,
        });
        trigger(event.target.name as keyof RaceDetailsType);
    };

    const onSelectFieldChange = (field: string, value: string | number): void => {
        let objValue;
        const isError = getFieldState(field as keyof RaceDetailsType).invalid;

        try {
            // eslint-disable-next-line quotes
            objValue = typeof value === "string" && value.includes('"') ? JSON.parse(value) : value;
        } catch (e) {
            console.error("Error parsing", e);
        }

        setValue(field as keyof RaceDetailsType, objValue, {
            shouldDirty: true,
        });

        if (isError) trigger(field as keyof RaceDetailsType);
    };

    return (
        <div className="add-edit-race-wrap" ref={addEditRef}>
            <div className="add-edit-race-wrap" ref={addEditRef}>
                <form className="add-edit-race" ref={formRef}>
                    <ComponentWithFormContext
                        initialData={raceData}
                        values={values}
                        scrollToErrors={(errors: string[]) => scrollToOnErrors(errors)}
                        isDirty={isDirty}
                        isSubmitting={isSubmitting}
                        errors={errors}
                    />

                    <div className="add-edit-race__form-wrap">
                        <div className="add-edit-race__form-item">
                            <div className="add-edit-race__race-information add-edit-race__card">
                                <div className="add-edit-race__top-wrap">
                                    <div className="add-edit-race__top-item">
                                        {renderPageHeader()}
                                        <div className="add-edit-race__required-fields-info">
                                            <Typography.Text>
                                                Fields marked with (
                                                <span className="add-edit-race__required">*</span>)
                                                are required
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-edit-race__card-top">
                                    <Typography.Title level={4}>General info</Typography.Title>
                                </div>
                                <div className="add-edit-race__content">
                                    <Row gutter={[25, 8]} style={{ marginBottom: "2rem" }}>
                                        <Col span={24} md={8} data-error="name">
                                            <FormItemWrapper
                                                label="Race name"
                                                error={errors.name}
                                                required
                                            >
                                                <Input
                                                    type="text"
                                                    status={errors.name ? "error" : ""}
                                                    value={watch("name")}
                                                    {...register("name")}
                                                    onChange={onFieldChange}
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                        <Col span={24} md={8} data-error="start_date">
                                            <FormItemWrapper
                                                label="Race date"
                                                error={errors.start_date}
                                                required
                                            >
                                                <Controller
                                                    control={control}
                                                    name="start_date"
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            name,
                                                            onBlur,
                                                            ...rest
                                                        },
                                                    }) => {
                                                        const date = watch("start_date");

                                                        return (
                                                            <DatePicker
                                                                value={
                                                                    date
                                                                        ? parseISO(date)
                                                                        : undefined
                                                                }
                                                                style={{ width: "100%" }}
                                                                disabledDate={(
                                                                    current: Date
                                                                ): boolean =>
                                                                    current &&
                                                                    current.valueOf() < Date.now()
                                                                }
                                                                format={
                                                                    user?.location?.language ===
                                                                    "en-us"
                                                                        ? MISC_CONSTANTS.dateFormat
                                                                        : "D MMMM YYYY"
                                                                }
                                                                status={
                                                                    errors.start_date ? "error" : ""
                                                                }
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                    }
                                                                }}
                                                                onChange={(newDate, dateString) => {
                                                                    if (_isEmpty(dateString)) {
                                                                        setValue(name, "");
                                                                        trigger(name);
                                                                        return;
                                                                    }

                                                                    if (
                                                                        typeof dateString ===
                                                                        "string"
                                                                    ) {
                                                                        setValue(
                                                                            name,
                                                                            DateUtils.formatDateRegex(
                                                                                dateString
                                                                            )
                                                                        );
                                                                    }
                                                                    trigger(name);
                                                                }}
                                                                getPopupContainer={(triggerNode) =>
                                                                    triggerNode.offsetParent as HTMLElement
                                                                }
                                                                id="start_date"
                                                                {...rest}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                        <Col span={24} md={8} data-error="type">
                                            <FormItemWrapper
                                                label="Race type"
                                                error={errors.type?.name}
                                                required
                                            >
                                                <Select
                                                    style={{ width: "100%", zIndex: 200 }}
                                                    status={errors.type ? "error" : ""}
                                                    options={raceTypeOptions}
                                                    value={
                                                        formData?.types?.find(
                                                            (o) => o.name === watch("type")?.name
                                                        )?.display_name
                                                    }
                                                    {...register("type")}
                                                    onChange={(value) =>
                                                        onSelectFieldChange("type", value)
                                                    }
                                                    suffixIcon={<ChevronDownOutlined />}
                                                    id="type"
                                                    showSearch
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={[25, 8]}
                                        style={{ marginBottom: "2rem" }}
                                        data-error="description"
                                    >
                                        <Col span={24}>
                                            <FormItemWrapper
                                                label="Race description"
                                                error={errors.description}
                                                required
                                            >
                                                <TextArea
                                                    rows={6}
                                                    status={errors.description ? "error" : ""}
                                                    value={watch("description")}
                                                    {...register("description")}
                                                    onChange={onFieldChange}
                                                    id="description"
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={[25, 8]}
                                        style={{ marginBottom: "2rem" }}
                                        data-error="website"
                                    >
                                        <Col span={24} md={12}>
                                            <FormItemWrapper
                                                label="Race website"
                                                error={errors.website}
                                                required
                                            >
                                                <Input
                                                    type="text"
                                                    status={errors.website ? "error" : ""}
                                                    value={watch("website")}
                                                    {...register("website")}
                                                    onChange={onFieldChange}
                                                    prefix={<InternetOutlined />}
                                                    id="website"
                                                />
                                            </FormItemWrapper>
                                        </Col>

                                        <Col span={24} md={12} data-error="registration_page">
                                            <FormItemWrapper
                                                label="Race registration page"
                                                error={errors.registration_page}
                                                required
                                            >
                                                <Input
                                                    type="text"
                                                    status={errors.registration_page ? "error" : ""}
                                                    value={watch("registration_page")}
                                                    {...register("registration_page")}
                                                    onChange={onFieldChange}
                                                    prefix={<InternetOutlined />}
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                    </Row>

                                    <Row gutter={[25, 8]}>
                                        <Col
                                            span={24}
                                            className="add-edit-race__logo-column"
                                            data-error="logo"
                                        >
                                            <Controller
                                                control={control}
                                                name="logo"
                                                render={({
                                                    field: { onChange, value, name, ...rest },
                                                }) => (
                                                    <div className="add-edit-race__logo-image-wrap">
                                                        <Typography.Text
                                                            strong
                                                            className="ant-label"
                                                            data-label="upload-label"
                                                        >
                                                            Race logo (
                                                            <span className="ant-label-required">
                                                                *
                                                            </span>
                                                            )
                                                        </Typography.Text>

                                                        <div
                                                            className={`upload-dragger-wrap ${
                                                                errors?.logo ? "isError" : ""
                                                            }`}
                                                        >
                                                            <Upload
                                                                accept="image/png, image/jpeg, image/jpg"
                                                                maxCount={1}
                                                                listType="picture-card"
                                                                multiple={false}
                                                                fileList={fileList}
                                                                onRemove={() => {
                                                                    setFileList([]);
                                                                    setValue(name, undefined);
                                                                }}
                                                                beforeUpload={handleLogoChange}
                                                                showUploadList={{
                                                                    showPreviewIcon: false,
                                                                }}
                                                                onChange={(info) => {
                                                                    if (
                                                                        info.fileList.length === 0
                                                                    ) {
                                                                        setError(name, {
                                                                            type: "manual",
                                                                            message:
                                                                                "Logo is required",
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <button
                                                                    className="business-listing__upload-btn"
                                                                    type="button"
                                                                >
                                                                    <PlusOutlined />
                                                                    <div style={{ marginTop: 8 }}>
                                                                        Upload
                                                                    </div>
                                                                </button>
                                                            </Upload>

                                                            <Typography.Text>
                                                                Max file size: 2mb | Accepted: .jpg,
                                                                .jpeg, .png
                                                            </Typography.Text>
                                                        </div>

                                                        <Typography.Text className="ant-error-label">
                                                            {errors.logo?.message}
                                                        </Typography.Text>
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="add-edit-race__race-location add-edit-race__card">
                                <div className="add-edit-race__card-top">
                                    <Typography.Title level={4}>Location</Typography.Title>
                                </div>
                                <div className="add-edit-race__content">
                                    <Row gutter={[25, 8]} style={{ marginBottom: "2rem" }}>
                                        <Col span={24} md={12} data-error="venue">
                                            <FormItemWrapper label="Venue" error={errors.venue}>
                                                <Input
                                                    type="text"
                                                    status={errors.venue ? "error" : ""}
                                                    value={watch("venue") || ""}
                                                    {...register("venue")}
                                                    onChange={onFieldChange}
                                                    id="venue"
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                        <Col span={24} md={12} data-error="address">
                                            <FormItemWrapper
                                                label="Address"
                                                error={errors.address}
                                                required
                                            >
                                                <Input
                                                    type="text"
                                                    status={errors.address ? "error" : ""}
                                                    value={watch("address")}
                                                    {...register("address")}
                                                    onChange={onFieldChange}
                                                    id="address"
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                    </Row>

                                    <Row gutter={[25, 8]}>
                                        <Col span={24} md={6} data-error="city">
                                            <FormItemWrapper
                                                label="City"
                                                error={errors.city}
                                                required
                                            >
                                                <Input
                                                    type="text"
                                                    status={errors.city ? "error" : ""}
                                                    value={watch("city")}
                                                    {...register("city")}
                                                    onChange={onFieldChange}
                                                    id="city"
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                        <Col span={24} md={6}>
                                            <FormItemWrapper
                                                label={t("form.region")}
                                                error={errors.region?.name}
                                                required
                                            >
                                                <Select
                                                    style={{ width: "100%" }}
                                                    status={errors.region?.name ? "error" : ""}
                                                    options={raceRegionOptions}
                                                    value={watch("region")?.name}
                                                    {...register("region")}
                                                    showSearch
                                                    onChange={(value) =>
                                                        onSelectFieldChange("region", value)
                                                    }
                                                    suffixIcon={<ChevronDownOutlined />}
                                                    id="region"
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                        <Col span={24} md={6} data-error="postal_code">
                                            <FormItemWrapper
                                                label={t("form.postal_code")}
                                                error={errors.postal_code}
                                                required
                                            >
                                                <Input
                                                    type="text"
                                                    status={errors.postal_code ? "error" : ""}
                                                    value={watch("postal_code")}
                                                    {...register("postal_code")}
                                                    onChange={onFieldChange}
                                                    id="postal_code"
                                                />
                                            </FormItemWrapper>
                                        </Col>
                                        {user?.location.language !== "en-gb" && (
                                            <Col span={24} md={6} data-error="timezone">
                                                <FormItemWrapper
                                                    label="Timezone"
                                                    error={errors.timezone?.code}
                                                    required
                                                >
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        status={errors.timezone ? "error" : ""}
                                                        value={
                                                            formData?.timezones?.find(
                                                                (o: any) =>
                                                                    o.code ===
                                                                    watch("timezone")?.code
                                                            )?.display_name
                                                        }
                                                        {...register("timezone")}
                                                        onChange={(value) =>
                                                            onSelectFieldChange("timezone", value)
                                                        }
                                                        options={raceTimezoneOptions}
                                                        className="add-race__timezone-input"
                                                        suffixIcon={<ChevronDownOutlined />}
                                                        id="timezone"
                                                        showSearch
                                                    />
                                                </FormItemWrapper>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <div className="add-edit-race__form-item">
                            <div className="add-edit-race__race-events add-edit-race__card">
                                <div className="add-edit-race__card-top">
                                    <Typography.Title level={4}>Events</Typography.Title>
                                </div>
                                <div
                                    className="add-edit-race__events-wrapper"
                                    style={{ marginTop: 0 }}
                                    data-error="events"
                                >
                                    <div className="add-edit-race__events">
                                        {events.map((event, index: number) => (
                                            <RaceEvent
                                                formContext={formContext}
                                                index={index as number}
                                                key={index as number}
                                                event={event}
                                                remove={() => removeEvent(index)}
                                                participants={formData.participants}
                                                distance_units={formData.distance_units}
                                                isLastItem={events.length === 1}
                                                onSelectFieldChange={onSelectFieldChange}
                                            />
                                        ))}
                                    </div>
                                    <div className="add-edit-race__event-action">
                                        <button
                                            onClick={eventAddHandler}
                                            className="link"
                                            type="button"
                                        >
                                            Add event
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="add-edit-race__form-cta-container">
                            <div className="add-edit-race__form-cta">
                                <Button
                                    type="primary"
                                    loading={withRedirectState && savingRaceIsLoading}
                                    disabled={savingRaceIsLoading}
                                    className="responsive-cta"
                                    onClick={handleSubmit((data) => submitRace(data, true))}
                                >
                                    <span>Save</span>
                                </Button>

                                {mode === "edit" && (
                                    <Button
                                        loading={!withRedirectState && savingRaceIsLoading}
                                        disabled={savingRaceIsLoading}
                                        className="responsive-cta ant-btn-primary-outline"
                                        type="default"
                                        onClick={handleSubmit((data) => submitRace(data, false))}
                                    >
                                        <span>Save & continue editing</span>
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddEditRace;
