import STORAGE_CONSTANTS from "app/constants/storage";
import Storage from "app/utils/storage/local";
import "app/hoc/layout/layout.scss";
import MISC_CONSTANTS from "app/constants/misc";
import VendorLayout from "./business_layout";
import Layout from "./dashboard_layout";

const AutoLayout = () => {
    const currentDashboard = Storage.get(`${STORAGE_CONSTANTS.currentDashboard}`);

    return currentDashboard === MISC_CONSTANTS.BUSINESS_DASHBOARD ? <VendorLayout /> : <Layout />;
};

export default AutoLayout;
